<template>
    <div>
        <div class="container-form">
            <form action="">
                <div class="container-produto">
                    <div class="label label-produto">
                        <span class="produto"> Produto <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="produto-img">
                        <div class="open-img" @click="showImagem = true">
                            <img src="../assets/img/fullscreen.svg" alt="abrir imagem" />
                        </div>
                        <img
                            id="produtoImg"
                            :src="'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' + codigo + '.webp'"
                            @error="replaceByDefault"
                            alt="produto 1"
                        />
                    </div>
                </div>
                <div class="container-codigo">
                    <div class="label label-codigo">
                        <span class="codigo"> Código de barras <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-codigo">
                        <input type="number" autocorrect="off" v-model="codigo" disabled />
                    </div>
                </div>
                <div class="container-descricao">
                    <div class="label label-descricao">
                        <span class="descricao"> Descrição <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-descricao">
                        <input type="text" v-model="descricao" disabled />
                    </div>
                </div>
                <div class="container-quantidade">
                    <div class="label label-quantidade">
                        <span class="quantidade"> Preço na loja <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-quantidade">
                        <input
                            type="number"
                            min="0"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="99999"
                            maxlength="5"
                            placeholder="00000"
                            v-model="preco"
                            required
                            @input="validaPreco"
                        />
                    </div>
                    <div v-if="precoIsInvalid" class="mensagemErro">Preço inválido</div>
                </div>
                <div class="containerQV">
                    <div class="container-validade">
                        <div class="label label-validade">
                            <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                        </div>
                        <div class="input input-validade">
                            <input type="text" v-model="dataValidade" disabled />
                        </div>
                    </div>
                    <div class="container-quantidade">
                        <div class="label label-quantidade">
                            <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                            <tooltip class="tooltip-qnt" :isLeft="true" :msg="msgQnt"></tooltip>
                        </div>
                        <div class="input input-quantidade">
                            <input
                                type="number"
                                min="0"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                max="99999"
                                maxlength="5"
                                placeholder="00000"
                                v-model="quantidade"
                                required
                                @input="validaQuantidade"
                            />
                            <div class="controle">
                                <button id="btn-minus" type="button" @click="decrementQuantidade">
                                    <img src="../assets/img/minus.svg" alt="subtrair" />
                                </button>
                                <button id="btn-plus" type="button" @click="incrementQuantidade">
                                    <img src="../assets/img/plus.svg" alt="adicionar" />
                                </button>
                            </div>
                        </div>
                        <div v-if="quantidadeIsInvalid" class="mensagemErro">Quantidade inválida</div>
                    </div>
                </div>
                <div class="container-validade-loja">
                    <div class="label label-validade-loja">
                        <span class="validade-loja"> Validades na loja </span>
                    </div>
                    <div class="input input-validade-loja">
                        <textarea v-model="validades" disabled class="input-textarea"></textarea>
                    </div>
                </div>
                <div class="container-inclusao">
                    <div class="label label-inclusao">
                        <span class="inclusao"> Deseja fazer uma inclusão desse produto? </span>
                    </div>
                    <div class="container-btnInclusao">
                        <button @click="enviaForm(false)" type="button">Não</button>
                        <button @click="enviaForm(true)" type="button">Sim</button>
                    </div>
                </div>
            </form>
        </div>

        <footer-save @editaInclusao="editaInclusao" @envia-form="enviaForm(false)"></footer-save>

        <transition name="fade">
            <div class="tela-over" v-if="showImagem">
                <div class="tela-imagem">
                    <div class="close-imagem" @click="showImagem = false">
                        <img src="../assets/img/close-black.svg" alt="fechar a imagem" />
                    </div>
                    <img
                        id="imgFull"
                        :src="'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' + codigo + '.webp'"
                        @error="replaceByDefault"
                        alt="produto 1"
                    />
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="showConfirm" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Descartar alterações?
                        <div class="close-cancel" @click="showConfirm = false">
                            <img src="../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>Todas as alterações serão perdidas</h3>
                    <div class="cancel-btn">
                        <button @click="showConfirm = false" type="button">Não</button>
                        <button @click="voltaPagina" type="button">Descartar</button>
                    </div>
                </div>
            </div>
        </transition>

        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import FooterSave from '../components/FooterSave';
import Tooltip from '../components/Tooltip';
import HTTP from '../http/http.js';
import MsgSucess from '../components/MsgSucess';
import MsgError from '../components/MsgError';
import img from '../assets/img/broken-image.svg';

export default {
    components: {
        FooterSave,
        Tooltip,
        MsgSucess,
        MsgError,
    },

    data() {
        return {
            codigo: '',
            descricao: '',
            validades: '',
            quantidade: '',
            dataValidade: '',
            preco: '',
            precoIsInvalid: false,
            quantidadeIsInvalid: false,
            produto: null,
            showImagem: false,
            showValidade: false,
            showConfirm: false,
            showSucess: false,
            showAlert: false,
            mensagem: {},
            msgQnt: 'Quantidade existente do produto na loja com a data de validade indicada.',
            msgS: 'Tarefa concluida com sucesso',
            msgE: 'A tarefa não pode ser concluida, tente novamente. Se o erro persistir, contate seu supervisor.',
        };
    },

    computed: {
        getTarefa() {
            return this.$store.getters.getTarefa;
        },
        getTarefas() {
            return this.$store.getters.getTarefas;
        },
    },

    created() {
        let codigo = this.$route.params.codigo;
        let validade = this.$route.params.validade;

        this.$store.dispatch('setCodigo', codigo);
        this.$store.dispatch('setValidade', validade);

        this.produto = this.getTarefa;
        if (this.produto == null) {
            this.$router.go(-1);
        } else {
            this.descricao = this.produto.descricao;
            this.dataValidade = this.convertDate(this.produto.data_validade);
            let validadesString = this.produto.validades_existentes;
            let validadesArray = validadesString.split(',');
            let validadesAA = [];
            validadesArray.forEach((item) => {
                let retirarAno = item.slice(6, 8);
                let replaceAno = item.replace(`/${retirarAno}`, '/');
                validadesAA.push(replaceAno);
            });
            this.validades = validadesAA.join(', ');
            this.codigo = this.produto.codigo_de_barras;
        }
    },

    methods: {
        closeModal() {
            this.$router.push({ name: 'alert' });
        },

        incluirProduto() {
            this.$store.dispatch('setCodigoInclusao', this.codigo);
            this.$router.push({ name: 'incluirProdutos' });
        },

        enviaForm(vaiAdicionarProduto) {
            if (!this.validaForm()) return;

            let codigo = this.$route.params.codigo;
            let validade = this.$route.params.validade;
            let usuario = sessionStorage.getItem('usuario');

            let data = {
                codigo_de_barras: this.codigo,
                quantidade: this.quantidade,
                validade: validade,
                preco_venda: this.preco,
                usuario: usuario,
            };

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/promotores/tarefas`, data)
                    .then(() => {
                        this.showSucess = true;
                        const itemToBeRemoved = { codigo, validade };

                        let todasTarefas = this.getTarefas;

                        todasTarefas.splice(
                            todasTarefas.findIndex(
                                (a) =>
                                    a.codigo_de_barras == itemToBeRemoved.codigo &&
                                    a.data_validade == itemToBeRemoved.validade
                            ),
                            1
                        );
                    })
                    .catch(() => {
                        this.showAlert = true;
                        console.error('erro');
                    });
            } else {
                let produtosSalvos = JSON.parse(localStorage.getItem('alertasValidade'));

                let dataToLS = null;

                const itemToBeRemoved = { codigo, validade };

                let todasTarefas = this.getTarefas;

                todasTarefas.splice(
                    todasTarefas.findIndex(
                        (a) =>
                            a.codigo_de_barras == itemToBeRemoved.codigo && a.data_validade == itemToBeRemoved.validade
                    ),
                    1
                );

                if (produtosSalvos) {
                    produtosSalvos.push(data);

                    dataToLS = JSON.stringify(produtosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.showSucess = true;

                localStorage.setItem('alertasValidade', dataToLS);

                this.$store.dispatch('plusNumeroSync');
            }

            if (vaiAdicionarProduto) {
                setTimeout(() => {
                    this.showSucess = false;
                    this.incluirProduto();
                }, 3500);
            } else {
                setTimeout(() => {
                    this.showSucess = false;
                    this.voltaPagina();
                }, 3500);
            }
        },

        convertDate(date) {
            let dateSplit = date.split('-');
            let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
            return newDate;
        },

        enviaFormSemValidade() {
            setTimeout(() => {
                this.showSucess = false;
                this.incluirProduto();
            }, 2000);
        },

        closeError() {
            this.showAlert = false;
        },

        validaQuantidade() {
            if (this.quantidade == '') {
                this.quantidade = 0;
            }

            this.quantidadeIsInvalid = this.quantidade < 0 ? true : false;
        },

        validaPreco() {
            this.precoIsInvalid = this.preco <= 0 ? true : false;
        },

        validaForm() {
            this.validaQuantidade();
            this.validaPreco();

            if (this.quantidadeIsInvalid == true || this.precoIsInvalid == true) {
                return false;
            }

            return true;
        },

        decrementQuantidade() {
            this.quantidade--;
            if (this.quantidade < 0) {
                this.quantidade = 0;
            }
        },

        incrementQuantidade() {
            this.quantidade++;
            if (this.quantidade > 99999) {
                this.quantidade = 99999;
            }
        },

        editaInclusao() {
            this.showConfirm = true;
        },

        voltaPagina() {
            this.$router.go(-1);
        },

        replaceByDefault(e) {
            e.target.src = img;
        },
    },
};
</script>

<style scoped>
.container-form {
    padding: 15px;
    margin-top: 56px;
    margin-bottom: 66px;
    overflow-x: hidden;
}

.containerQV {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.nova-validade {
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.nova-validade2 {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -5px;
    margin-bottom: 15px;
    justify-content: space-between;
    flex-direction: column;
}

.nova-validade h3,
.nova-validade2 h3 {
    color: var(--cinza-escuro);
    font-size: 14px;
}

.nova-validade h3 small,
.nova-validade2 h3 small {
    font-size: 11px;
}

.label span {
    font-size: 0.83rem;
}

.obrigatorio {
    color: var(--vermelho);
}

form div div span {
    color: var(--cinza-escuro);
}

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 5px;
    place-content: center;
}

#produtoImg {
    max-width: 60%;
    max-height: 35vh;
    min-height: 80px;
    margin: auto;
}

input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    font-size: 0.85rem !important;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--verde);
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input:disabled {
    background-color: #cdcdcd;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.55rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: none;
}

#btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

#btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.input-codigo,
.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
}

.input-quantidade input {
    text-align: center;
    padding: 12px 8px;
}

.input {
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;
}

.mensagemErro {
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.8rem;
    color: var(--vermelho);
}

.tela-over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-imagem {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 100px 25px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#imgFull {
    max-width: 250px;
    max-height: 250px;
    border: 1px solid #bdc1c6;
    padding: 20px;
}

.open-img {
    position: absolute;
    display: flex;
    right: 21px;
    cursor: pointer;
    box-shadow: 0 0 0 2px #cdcdcd;
    background-color: #ececec;
    transition: all 0.2s;
    border-radius: 50%;
    padding: 10px;
}

.open-img:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

.open-img img {
    width: 36px;
}

.close-imagem {
    position: absolute;
    top: -21px;
    right: -21px;
    box-shadow: 0 0 0 2px #cdcdcd;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ececec;
    transition: all 0.2s;
}

.close-imagem:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

.containerRemove {
    position: relative;
}

.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    min-width: 180px;
    font-size: 16px;
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 20px;
    margin-top: 13px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.label-float input:required:invalid + label {
    color: var(--cinza);
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: -5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    color: var(--verde);
}

.container-save {
    width: 100%;
    border-top: 1px solid var(--cinza);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.container-btnInclusao {
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
}

.container-btnInclusao button {
    margin-top: 5px;
    padding: 5px 15px;
    background-color: #fff;
    border: 1px solid var(--cinza);
    border-radius: 5px;
    cursor: pointer;
}

.container-btnInclusao button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
    border: 1px solid #dbdbdb;
}

.input-textarea {
    resize: none;
    width: 100%;
    padding: 8px;
    height: fit-content;
    border-radius: 4px;
    font-size: 0.85rem;
    background-color: #cdcdcd;
}

.btn-scan {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.label {
    display: flex;
    align-items: center;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 10%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 16px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
}

.sucess {
    background-color: var(--verde);
    padding: 10px 20px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sucess img {
    width: 90px;
}

.sucess-msg {
    padding: 10px;
    height: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.sucess-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btn-sucess {
    padding: 5px;
    border: 1px solid #ab2121;
    border-radius: 10px;
    background-color: #fff;
    color: var(--verde);
    cursor: pointer;
}

.btn-sucess:hover {
    background-color: var(--verde);
    color: #fff;
    position: relative;
    top: 5px;
}

@media (max-width: 319px) {
    .open-img img {
        width: 22px;
    }

    #produtoImg {
        max-width: 45%;
    }

    .containerQV {
        display: flex;
        flex-direction: column;
    }

    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .tooltip-qnt {
        margin-left: 80px;
    }
}

@media (max-width: 359px) {
    .label span {
        font-size: 14px;
    }

    input {
        font-size: 15px;
    }

    .nova-validade h3 {
        font-size: 13px;
    }

    .tela-mensagem h3 {
        font-size: 15px;
    }

    .cancel-msg {
        font-size: 18px;
    }
}
</style>
